/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql } from 'gatsby';
// import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Services from '../components/Common/Services';
import Lead from '../components/Common/Lead';

const siteMetadata = {
  title: 'Skup medali, orderów i odznaczeń wojskowych - Monety Olsztyn',
  description: 'Poszukujesz profesjonalnego skupu medali, orderów i odznaczeń wojskowych? A może masz stare dokumenty, które chciałbyś sprzedać? Chętnie pomożemy w wycenie. Skupujemy papiery wartościowe oraz stare akcje i obligacje. Skontaktuj się z nami, aby dowiedzieć się więcej!',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-medali-orderow-odznaczen/',
  locale: 'pl_PL',
};

const ValuationPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <section className="section-text section-text--no-border">
      <h1>
        <span>Skup medali</span> <span>i odznaczeń wojskowych</span>
      </h1>

      <Lead
        desc="Stare medale i odznaczenia wojskowe mają nie tylko wartość historyczną, ale także materialną. Dzięki nam możesz odkryć, ile naprawdę są warte Twoje pamiątki."
      />

      <p>Chętnie&nbsp;podejmie się wyceny, biorąc pod uwagę rzadkość, stan zachowania oraz historyczne znaczenie przedmiotów. Interesują nas medale z różnych okresów historycznych – od XIX wieku po czasy współczesne. Poszukujemy zarówno medali polskich, jak i zagranicznych, związanych z ważnymi wydarzeniami historycznymi.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.odznaki_oficerskie)}
          alt="5 polskich odznaczeń oficerskich"
        />
        <figcaption>Polskie odznaki oficerskie</figcaption>
      </figure>

      <h2>W naszym sklepie numizmatycznym<br />wyceniamy i skupujemy:</h2>

      <ul>
        <li>odznaki, ordery i <strong>odznaczenia wojskowe</strong></li>
        <li>odznaki pułkowe i batalionowe</li>
        <li>różnego rodzaju medale okolicznościowe</li>
      </ul>

      <h3>Skupujemy również:</h3>

      <ul>
        <li>stare dokumenty</li>
        <li>stare akcje i obligacje</li>
        <li>stare papiery wartościowe</li>
      </ul>

      <figure style={{ marginTop: '100px' }}>
        <GatsbyImage
          image={getImage(data.odznaki_pulkowe)}
          alt="5 polskich odznak pułkowych, oficerskich i pamiątkowych"
        />
        <figcaption>Polskie odznaki pułkowe, oficerskie i pamiątkowe</figcaption>
      </figure>

      <h2>Skup orderów w Olsztynie<br />- jak działamy?</h2>

      <p>Proces skupu starych medali i odznaczeń wojskowych jest prosty i przejrzysty. <Link to="/kontakt/">Skontaktuj się z nami</Link>, a my zajmiemy się resztą. Po wstępnej ocenie przeprowadzimy dokładną analizę, a następnie zaproponujemy cenę za Twoje przedmioty.</p>
    </section>

    <Services />
  </>
);

export const query = graphql`
  {
    odznaki_oficerskie: file(relativePath: {regex: "/odznaki-oficerskie/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
    odznaki_pulkowe: file(relativePath: {regex: "/odznaki-pulkowe/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
  }
`;

export default ValuationPage;
